"use client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AxiosInstance } from "axios";
import { prepareUrl } from "@/utils/url";
import useLazyLoadList, { UseLazyLoadList } from "./useLazyLoadList";
import getProductsFilter from "@/modules/public/components/filters/getProductsFilter";
import { hashObject } from "@/utils/data";
import useClientContext from "@/context/ClientContext";

// Use only for the public products list
interface UseLazyLoadListWithFilter<T> extends Omit<UseLazyLoadList<T>, "byPage" | "provider"> {
  key: any;
  provider?: AxiosInstance;
  route: string;
  initQuery: any;
  transformResponse?: (response: any) => T;
  apiQueryParams?: { [param: string]: string };
  routeQueryParams?: { [param: string]: string };
  onChangeUrl: (nextRoute: string) => void;
}

const useLazyLoadListWithFilter = <T extends unknown>(argsout: UseLazyLoadListWithFilter<T>) => {
  const {
    key,
    provider,
    url,
    initList,
    transformResponse,
    nextPage,
    debounce,
    onError,
    route,
    onChangeUrl,
    initQuery = {},
    apiQueryParams = {},
    routeQueryParams = {},
  } = argsout;
  const { locale } = useClientContext();

  const [args, setArgs] = useState({ key, provider, initList, transformResponse, url, initRequest: false, nextPage, onError });

  useEffect(() => {
    if (args.url !== url || args.key !== key) {
      setArgs({ key, provider, initList: [], transformResponse, url, initRequest: true, nextPage, onError });
    }
  }, [args.key, args.url, key, nextPage, onError, provider, transformResponse, url]);

  const [filter, setFilter] = useState<{ [key: string]: string }>(initQuery);
  const onChangeFilter = useCallback(
    (newFilter: { [key: string]: string }) => {
      if (hashObject(newFilter, [undefined, ""]) !== hashObject(filter, [undefined, ""])) {
        setFilter(newFilter);

        const preparedURL = prepareUrl(`${url}`.split("?")[0], getProductsFilter(newFilter), getProductsFilter(apiQueryParams));

        setArgs({
          key,
          provider,
          initList: [],
          transformResponse,
          url: preparedURL,
          initRequest: true,
          nextPage: null,
          onError,
        });

        const qs = { ...routeQueryParams, ...newFilter };
        const arr = Object.keys(qs).map((p) => (qs[p] ? `${p}=${qs[p]}` : ""));

        const nextRoute = prepareUrl(`${route}`, ...arr);

        onChangeUrl(`/${locale}${nextRoute}`);
      }
    },
    [filter, url, apiQueryParams, key, provider, transformResponse, onError, routeQueryParams, route, onChangeUrl, locale]
  );

  const onResetFilter = useCallback(
    (param?: string, value?: string) => {
      if (!param) {
        // Reset all filters
        onChangeFilter(routeQueryParams);
      }

      if (param === "brand") {
        onChangeFilter({
          ...filter,
          brand: `${filter.brand}`
            .split(",")
            .filter((v) => v !== value)
            .join(","),
        });
      } else if (param === "sale") {
        onChangeFilter({ ...filter, sale: "" });
      } else if (param === "free_delivery") {
        onChangeFilter({ ...filter, free_delivery: "" });
      } else if (param === "in_stock") {
        onChangeFilter({ ...filter, in_stock: "" });
      } else if (param === "price") {
        onChangeFilter({ ...filter, min_price: "", max_price: "" });
      } else if (param === "properties") {
        let newProps = `${filter.properties}`.replace(new RegExp(`${value},?`), "");
        newProps = newProps.replace(/\d+\:;/, "");
        newProps = newProps.replace(/,;/, ";");
        newProps = newProps.replace(/,$/, "");
        newProps = newProps.replace(/\d+\:$/, "");

        onChangeFilter({ ...filter, properties: newProps });
      }
    },
    [filter, onChangeFilter, routeQueryParams]
  );

  const { list, onNextPage, loading, dirty, ended } = useLazyLoadList<T>({
    ...args,
    debounce,
    byPage: false,
  });

  return useMemo(
    () => ({
      loading,
      dirty,
      list,
      onNextPage,
      ended,
      onChangeFilter,
      onResetFilter,
    }),
    [dirty, ended, list, loading, onChangeFilter, onNextPage, onResetFilter]
  );
};

export default useLazyLoadListWithFilter;
