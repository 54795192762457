"use client";
import { useCallback, useMemo } from "react";
import dynamic from "next/dynamic";
import cn from "@/libs/cn";
import { QUERY_SEARCH_PARAM_NAME } from "@/constants/common";
import PublicIcons from "@/components/basic/PublicIcon";
import Select from "@/controls/Select";
import useClientContext from "@/context/ClientContext";
import { IApiCategory } from "@/types/interfaces/categories";
import { IApiCategoryMapped } from "@/types/interfaces/aggregated";
import ProductsFound from "@/modules/home/components/ProductsFound";
import useClientRouter from "@/hooks/useClientRouter";
import routesPublic from "../../constants/routesPublic";
import PageTitle from "../PageTitle";
import MobileCategoryFilters from "../MobileFilters/MobileCategoryFilters";
import publicRequests from "../../requests";
import { CategoryList } from "../MobileFilters/MobileStoreCategoryFilters";

import styles from "./styles.module.scss";

const PanelCategoryResetFilters = dynamic(() => import("../filters/PanelCategoryResetFilters"), { ssr: false });
const ButtonSortByProducts = dynamic(() => import("../ButtonSortByProducts"), { ssr: false });

interface HeaderPageCategoryProps {
  category?: IApiCategory | null;
  virtualCategory?: IApiCategory | null;
  isSearchPage?: boolean;
  q?: string;
  count: number;
  mappedCategories: IApiCategoryMapped[];
  searchParams: {
    [param: string]: string;
  };
  promo?: { id: string; title: string };
}

const HeaderPageCategory = (props: HeaderPageCategoryProps) => {
  const { isSearchPage, q, count, category, virtualCategory, mappedCategories, searchParams, promo } = props;
  const { isMobileSize, translate, openModal, closeModal, locale } = useClientContext();
  const router = useClientRouter();
  const { category: categoryId } = searchParams;

  let title = virtualCategory ? virtualCategory.name : category?.name || translate("Search");
  if (promo) {
    title = promo.title;
  }

  const categories: { value: string; label: string }[] = useMemo(() => {
    const options: { value: string; label: string }[] = category
      ? [{ value: `${category.id}`, label: category.name }]
      : [{ value: "-1", label: translate("All categories") }];

    const arr = mappedCategories
      .filter((i) => (category ? i.parent === category.id : true))
      .map((i) => ({ value: `${i.id}`, label: i.name }));
    return [...options, ...arr];
  }, [category, mappedCategories, translate]);

  const onSelectCategory = useCallback(
    async (item: IApiCategory) => {
      closeModal();
      const breadcrumbs = await publicRequests.categoryBreadcrumbs(item.id);
      const slugs = breadcrumbs?.map((i) => i.slug) || [];
      router.push(`/${locale}${routesPublic.category()}${slugs.join("/")}/${q ? `?${QUERY_SEARCH_PARAM_NAME}=${q}` : ""}`);
    },
    [closeModal, locale, q, router]
  );

  const openModalCategoryFilters = useCallback(() => {
    openModal(() => <CategoryList category={category} onSelect={(item) => onSelectCategory(item)} />, {
      title: '',
      className: styles.modal,
      classNameHeader: styles.modalHeader,
    });
  }, [category, onSelectCategory, openModal]);

  return (
    <div className={styles.HeaderPageCategory}>
      <div className={cn(styles.categoryNameWrapper, isSearchPage && styles.searchPage, isSearchPage && isMobileSize && styles.mobileSearchPage)}>
        {isSearchPage ? (
          <div className={styles.wrapSearch}>
            {isMobileSize ? (
              <Select onChange={onSelectCategory} options={categories} value="-1" asNative={isMobileSize}>
                <ProductsFound
                  className={styles.found}
                  productsCount={count}
                  categoriesCount={mappedCategories.length}
                />
              </Select>
            ) : (
              <>
                <div className={styles.title}>
                  {translate("Search")}: <span className={styles.value}>{q}</span>
                </div>
                <ProductsFound className={styles.found} productsCount={count} categoriesCount={mappedCategories.length} />
              </>
            )}
          </div>
        ) : (
          <>
            {isMobileSize && categories.length > 1 ? (
              <div className={styles.wrapTitle} onClick={() => openModalCategoryFilters()}>
                <PageTitle className={styles.title}>{title}</PageTitle>
                <PublicIcons name="arrowBottom" className={styles.arrow} width={24} height={24} />
              </div>
            ) : (
              <PageTitle className={styles.title}>{title}</PageTitle>
            )}
          </>
        )}
        {!isMobileSize && <ButtonSortByProducts className={cn(styles.sortby, isSearchPage && styles.pageSearch)} />}
      </div>
      <div className={cn(styles.wrapButtons, isMobileSize && styles.mobileMargin)}>
        <div>
          <MobileCategoryFilters
            category={category}
            showDinamicFilters={isSearchPage}
            showBrands={!!promo || (!!category && !category?.child.length) || isSearchPage}
            mappedCategories={mappedCategories}
            searchParams={searchParams}
          />
        </div>
        <div>
          {isMobileSize && <ButtonSortByProducts className={cn(styles.sortby, isSearchPage && styles.pageSearch)} />}
        </div>
      </div>
      <PanelCategoryResetFilters
        categoryId={category?.id || categoryId}
        query={searchParams}
        onlyDesktop
        className={styles.resetPanel}
      />
    </div>
  );
};

export default HeaderPageCategory;
