import { TranslateFunc } from "@/hooks/useTranslate";
import { IApiOrderStatus } from "@/types/interfaces/orders";

export const sellerOrderGetTextStatus = (status: IApiOrderStatus, translate: TranslateFunc) => {
  if (status === IApiOrderStatus.PENDING) return translate("New (Pending)");
  if (status === IApiOrderStatus.PAYMENT_FAILED) return translate("Payment failed");
  if (status === IApiOrderStatus.APPROVED) return translate("Approved");
  if (status === IApiOrderStatus.SHIPPING) return translate("Shipping");
  if (status === IApiOrderStatus.SHIPPING_ON_THE_WAY) return translate("Shipping_on_the_way");
  if (status === IApiOrderStatus.ARRIVED) return translate("Arrived at the branch");
  if (status === IApiOrderStatus.RECEIVED) return translate("Received");
  // if (status === IApiOrderStatus.CANCEL_INITIATED) return translate("Cancel initiated");
  if (status === IApiOrderStatus.CANCEL_INITIATED_BY_SELLER) return translate("Cancel initiated by merchant");
  if (status === IApiOrderStatus.CANCEL_INITIATED_BY_CUSTOMER) return translate("Cancel initiated by buyer");
  if (status === IApiOrderStatus.CANCELED_BY_SELLER) return translate("Canceled by merchant");
  if (status === IApiOrderStatus.CANCELED_BY_CUSTOMER) return translate("Canceled by buyer");
  if (status === IApiOrderStatus.CANCELED_BY_MARKET) return translate("Canceled by market");
  if (status === IApiOrderStatus.RETURN_INITIATED) return translate("Return initiated");
  if (status === IApiOrderStatus.RETURN_VERIFIED_BY_SELLER) return translate("Return verified by merchant");
  if (status === IApiOrderStatus.RETURN_DECLINED_BY_SELLER) return translate("Return declined by merchant");
  if (status === IApiOrderStatus.RETURN_ARRIVED) return translate("Return arrived");
  if (status === IApiOrderStatus.REFUND) return translate("Refunded");

  return status;
};

export const customerOrderGetTextStatus = (status: IApiOrderStatus, translate: TranslateFunc) => {
  if (status === IApiOrderStatus.PENDING) return translate("New (Pending)");
  if (status === IApiOrderStatus.PAYMENT_FAILED) return translate("Payment failed");
  if (status === IApiOrderStatus.APPROVED) return translate("Approved");
  if (status === IApiOrderStatus.SHIPPING) return translate("Shipping");
  if (status === IApiOrderStatus.SHIPPING_ON_THE_WAY) return translate("Shipping_on_the_way");
  if (status === IApiOrderStatus.ARRIVED) return translate("Arrived at the branch");
  if (status === IApiOrderStatus.RECEIVED) return translate("Received");
  // if (status === IApiOrderStatus.CANCEL_INITIATED) return translate("Cancel initiated");
  if (status === IApiOrderStatus.CANCEL_INITIATED_BY_SELLER) return translate("Cancel initiated by merchant");
  if (status === IApiOrderStatus.CANCEL_INITIATED_BY_CUSTOMER) return translate("Cancel initiated by buyer");
  if (status === IApiOrderStatus.CANCELED_BY_SELLER) return translate("Canceled by merchant");
  if (status === IApiOrderStatus.CANCELED_BY_CUSTOMER) return translate("Canceled by buyer");
  if (status === IApiOrderStatus.CANCELED_BY_MARKET) return translate("Canceled by market");
  if (status === IApiOrderStatus.RETURN_INITIATED) return translate("Return initiated");
  if (status === IApiOrderStatus.RETURN_VERIFIED_BY_SELLER) return translate("Return verified by merchant");
  if (status === IApiOrderStatus.RETURN_DECLINED_BY_SELLER) return translate("Return declined by merchant");
  if (status === IApiOrderStatus.RETURN_ARRIVED) return translate("Return arrived");
  if (status === IApiOrderStatus.REFUND) return translate("Refunded");

  return status;
};
