export const formatNumber = (val?: string | number | null, fractionDigits?: number) => {
  function funcToFixed(v: number) {
    if (typeof fractionDigits === "number") {
      return fractionDigits === 0 ? `${Math.trunc(v)}` : v.toFixed(fractionDigits);
    } else {
      return `${v}`;
    }
  }

  if (typeof val === "undefined" || val === null) {
    return funcToFixed(0);
  }

  if (typeof val === "string" && val) {
    const n = parseFloat(val);
    if (Number.isNaN(n)) {
      return val;
    }
    return funcToFixed(n);
  }

  if (typeof val === "number" && !Number.isNaN(val)) {
    return funcToFixed(val);
  }

  return funcToFixed(0);
};

export const isZero = (val: any) => {
  if (!val) {
    return true;
  }

  try {
    const parsed = parseFloat(val);

    return Number.isNaN(parsed) || parsed === 0;
  } catch (err) {
    console.error("isZero", typeof val, val, err);

    return true;
  }
};

export const formatPrice = (val?: string | number | null, zeroSuppression?: boolean) =>
  zeroSuppression ? formatNumber(val, 2).replace(".00", "") : formatNumber(val, 2);

export const formatWeight = (val: any) => `${parseFloat(val)}`;

export const formatFileName = (val: any) =>
  val && typeof val === "string"
    ? val.length < 20
      ? val
      : `${val.substring(0, 15)}...${val.substring(val.length - 8, val.length)}`
    : ``;

export const regExpInsertSpace = /\B(?=(\d{3})+(?!\d))/g;

export const numberWithSpaces = (num?: string | number | null, fractionDigits?: number) => {
  if (typeof num === "undefined" || num === null) {
    return num;
  }



  let n = num;
  if (typeof n === "number") {
    n = formatNumber(n, fractionDigits);
  }

  if (Number.isNaN(parseFloat(n))) {
    return n;
  }

  const t = n.split(".");

  if (typeof fractionDigits === "undefined") {
    if (t.length === 1) {
      return `${t[0].replaceAll(regExpInsertSpace, " ")}`;
    }

    return `${t[0].replaceAll(regExpInsertSpace, " ")}.${t[1]}`;
  }

  if (fractionDigits === 0) {
    return `${t[0].replaceAll(regExpInsertSpace, " ")}`;
  } else {
    return `${t[0].replaceAll(regExpInsertSpace, " ")}.${t[1]}`;
  }
};

export const formatPhone = (phone: string) => {
  return phone.replace(/[\(\)\s]/g, "");
};

export const formatSecondsToTime = (durationInSec: number) => {
  let minutes = Math.floor((durationInSec / 60) % 60);
  let hours = Math.floor((durationInSec / (60 * 60)));

  const hoursStr = (hours < 10) ? "0" + hours : hours;
  const minutesStr = (minutes < 10) ? "0" + minutes : minutes;

  return hoursStr + ":" + minutesStr;
}

export const formatProductCount = (value: number) => {
  return value;
}