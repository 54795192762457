export const getIdFromSlug = (slug: string) => {
  const arr = slug.split("_");
  return arr[arr.length - 1];
};

export const mappingList = (aggregated?: { id: number; doc_count: number }[], listByIds?: { id: number }[]) => {
  if (!Array.isArray(aggregated) || !Array.isArray(listByIds)) {
    return [];
  }

  const merged: any[] = [];
  const notFound: number[] = [];

  aggregated.forEach((a) => {
    const find = listByIds.find((i) => i.id === a.id);

    if (find) {
      merged.push({ ...a, ...find });
    } else {
      notFound.push(a.id);
    }
  });

  if (notFound.length) {
    console.log(
      "Error mappingList(): not found ids:",
      listByIds[0],
      aggregated.map((i) => i.id),
      notFound
    );
  }

  return merged;
};

export const getCategoryFiltersStr = (query: any) => {
  const { search, store, categories, sale } = query;

  const filter = [];

  if (search) {
    filter.push(`search=${search}`);
  }
  if (sale && sale === "1") {
    filter.push(`sale=1`);
  }
  if (store) {
    filter.push(`store=${store}`);
  }
  if (categories) {
    filter.push(`categories=${categories}`);
  }

  return filter.join("&");
};