export type TypePrice = string | null | undefined;

export const calcMultiPrice = (oldPrice: TypePrice, quantity: number = 1): number | null => {
  const val = parseFloat(`${oldPrice}`) * quantity;

  return !Number.isNaN(val) ? val : null;
};

export const calcDiscountPercent = (newPrice: TypePrice, oldPrice: TypePrice, toFixed = 0): string | null => {
  if (!oldPrice) {
    return null;
  }

  const val = 100 - (parseFloat(`${newPrice}`) / parseFloat(`${oldPrice}`)) * 100;

  if (Number.isNaN(val) || val === 0) {
    return null;
  }

  return val > 0 ? `-${val.toFixed(toFixed)}` : null;
};
